<template>
  <div class="mx-2">
    <v-row class="my-4" align-content="center">
      <span class="fs-10 mr-2 pt-2">表示期間</span>
      <date-pick-input
        v-model="searchForm.dateFrom"
        :max="searchForm.dateTo"
        @change="getTableData"
      />
      <span class="mx-2 pt-1"> 〜 </span>
      <!--
        日付To時が必要になった時用にコメントアウト
      <date-pick-input
        v-model="searchForm.dateTo"
      /> -->
    </v-row>

    <app-section-loader :status="loadingPage" />
    <v-row class="mt-4">
      <v-data-table
        :headers="headers"
        :items="computedTableData"
        fixed-header
        :items-per-page="20"
        :height="'calc(100vh - 200px)'"
        :footer-props="{
          'items-per-page-text': '表示件数',
          'items-per-page-options': [20],
        }"
        :options.sync="options"
      >
        <template v-slot:item.upload_source="{ item }">
          {{ item.upload_source }}
          <span
            class="sw-badge-info"
            style="white-space: inherit; text-align: left"
            v-show="!item.multiple && item.upload_source_page"
          >
            page: {{ item.upload_source_page }}
          </span>
          <s-icon
            v-if="getDuplicateMessage(item) != ''"
            color="red-500"
            class="tw-float-right"
            icon="feather-alert-triangle"
            :tooltip="getDuplicateMessage(item)"
          />
        </template>

        <template v-slot:item.date_created="{ item }">
          <span class="mr-1">
            {{
              item.date_created.slice(5, 10).replace('T', ' ').replace('-', '/')
            }}
          </span>
          <span>
            {{ item.date_created.slice(11, 16) }}
          </span>
        </template>

        <template v-slot:item.user="{ item }">
          <span>{{ getUserName(item.user_organization_id) }}</span>
        </template>

        <template v-slot:item.file_size="{ item }">
          <div class="text-right">
            {{ getFileSize(item) }}
          </div>
        </template>

        <template v-slot:item.upload_way="{ item }">
          <div class="text-center">
            <v-chip
              v-if="getUploadWay(item.upload_way).content != ''"
              x-small
              label
              outlined
              class="px-2"
              :color="getUploadWay(item.upload_way).color"
            >
              {{ getUploadWay(item.upload_way).content }}
            </v-chip>
          </div>
        </template>

        <template v-slot:item.state="{ item }">
          <div class="text-center">
            <v-chip
              small
              label
              v-if="item.state === 'WAITING'"
              dark
              class="px-6"
            >
              読み取り待機中
            </v-chip>

            <v-chip
              small
              label
              v-else-if="item.state === 'STARTED'"
              dark
              class="px-6"
            >
              {{ $t('message.upload_tbl_uploading') }}
            </v-chip>

            <v-btn
              small
              v-else-if="item.state === 'SUCCESS'"
              color="blue"
              dark
              v-on:click="goToInvoice(item.local_id)"
            >
              {{ $t('message.upload_tbl_success') }}
              <v-icon dark right> check_circle </v-icon>
            </v-btn>

            <v-btn v-else-if="item.state === 'FAILURE'" color="yellow" small>
              {{ $t('message.invoice_upload_on_error_process') }}
              <br />
              {{ $t('message.invoice_upload_contact_support') }}
            </v-btn>
          </div>
        </template>

        <template v-slot:item.error="{ item }">
          {{ getErrorMessage(item) }}
        </template>
      </v-data-table>
    </v-row>
  </div>
</template>

<script>
import Axios from 'axios'
import Snackbar from 'Helpers/snackbar/index'
import DatePickInput from 'Components/Input/DatePickInput'
import { mapGetters } from 'vuex'
import { convertUplaodErrorMessage } from 'Helpers/invoice_upload/index'

/* uploadList format
{
  user_organization_id: '',
  local_id: '',
  image_summary_id: '',
  upload_source: '',
  upload_source_page: '',
  multiple: '',
  size: '',
  status: 0,
  processing: true,
  error: {
    name: '',
    message: '',
    params: {}
  }
}
*/

export default {
  components: {
    DatePickInput,
  },
  props: {
    user: Object,
  },
  data() {
    var t = this
    return {
      searchForm: {
        dateFrom: '',
        dateTo: '',
      },
      dialogDuplicateFile: false,
      userList: [],
      sysUser: null,
      uploadHistory: [],
      uploadQueueObj: [],
      loadingPage: false,
      options: {
        sortBy: ['date_created'],
        sortDesc: [true],
        rowsPerPage: -1,
      },
      headers: [
        {
          text: t.$t('message.invoice_upload_filename'),
          value: 'upload_source',
          width: '200px',
          sortable: false,
        },
        {
          text: '読取日時',
          value: 'date_created',
          width: '105px',
          sortable: true,
        },
        {
          text: '読取ユーザ',
          value: 'user',
          width: '95px',
          sortable: false,
        },
        {
          text: t.$t('message.invoice_upload_size'),
          value: 'file_size',
          width: '70px',
          sortable: false,
        },
        {
          text: t.$t('message.invoice_upload_way'),
          value: 'upload_way',
          width: '80px',
          sortable: false,
        },
        {
          text: t.$t('message.invoice_upload_status'),
          value: 'state',
          width: '130px',
          sortable: false,
        },
        {
          text: 'エラー内容',
          value: 'error',
          width: '140px',
          sortable: false,
        },
      ],
      drawerStatusArray: [],
      DISPLAY_SIDE_FILTER: 'block',
      MAX_LENGTH_DRAWER_STATUS: 20,
    }
  },
  mounted() {
    this.loadingPage = true
    this.setInitialDate()
    this.getUserData()
    this.getTableData()
  },
  computed: {
    ...mapGetters(['invoiceUploadList']),
    computedTableData() {
      let uploadList = this.invoiceUploadList.uploadList
      let uploadListRemoveDuplicate = this.removeDuplicateData(uploadList)
      let joinedTableData = uploadListRemoveDuplicate.concat(this.uploadHistory)

      return joinedTableData.filter(
        (item) =>
          this.convertToDate(item.date_created) >= this.dateObjectFromDate
      )
    },
    dateObjectFromDate() {
      return this.convertToDate(this.searchForm.dateFrom)
    },
  },
  methods: {
    getFileSize(item) {
      var finalSize = item.size
      var finalByte
      if (finalSize < 1000000) {
        finalSize = Math.floor(finalSize / 1000)
        finalByte = 'KB'
      } else {
        finalSize = Math.floor(finalSize / 1000000)
        finalByte = 'MB'
      }
      return finalSize + ' ' + finalByte
    },
    getUploadWay(way) {
      switch (way) {
        case 'drop':
          return { content: '読取画面', color: 'blue' }
        case 'email':
          return { content: 'メール', color: 'red' }
        case 'slack':
          return { content: 'Slack', color: 'green' }
        case 'chatwork':
          return { content: 'Chatwork', color: 'green' }
        case 'line':
          return { content: 'LINE', color: 'green' }
        case 'api':
          return { content: '外部API', color: 'brown' }
        case 'mobile':
          return { content: 'アプリ', color: 'orange' }
        default:
          return { content: '', color: '' }
      }
    },
    saveDrawerStatusArray() {
      try {
        localStorage.setItem(
          'drawer_status',
          JSON.stringify(this.drawerStatusArray)
        )
      } catch (e) {
        console.log(e)
      }
    },
    setLocalStorageDisplayMode() {
      try {
        let userId = this.$store.getters.getUserID
        this.drawerStatusArray = JSON.parse(
          localStorage.getItem('drawer_status')
        )

        if (!this.drawerStatusArray) {
          this.drawerStatusArray = []
        }

        let index = this.drawerStatusArray.findIndex(
          (item) => item.user_id === userId
        )

        if (index > 0) {
          this.drawerStatusArray[index].display_mode = 2
          this.saveDrawerStatusArray()
        } else {
          if (this.drawerStatusArray.length === this.MAX_LENGTH_DRAWER_STATUS) {
            let lastInputIndex = null
            let updateIndex = null

            for (let x = 0; x < this.drawerStatusArray.length; x++) {
              if (this.drawerStatusArray[x].last_input === true) {
                lastInputIndex = x
              }
              this.drawerStatusArray[x].last_input = false
            }

            const endIndex = this.MAX_LENGTH_DRAWER_STATUS - 1

            if (lastInputIndex === endIndex) {
              updateIndex = 0
            } else {
              updateIndex = lastInputIndex + 1
            }

            let data = {
              user_id: userId,
              status: this.DISPLAY_SIDE_FILTER,
              display_mode: 2,
              last_input: true,
            }

            if (updateIndex !== null) {
              this.drawerStatusArray[updateIndex] = data
            }
            this.saveDrawerStatusArray()
          } else {
            for (let x = 0; x < this.drawerStatusArray.length; x++) {
              this.drawerStatusArray[x].last_input = false
            }

            let data = {
              user_id: userId,
              status: this.DISPLAY_SIDE_FILTER,
              display_mode: 2,
              last_input: true,
            }

            this.drawerStatusArray.push(data)
            this.saveDrawerStatusArray()
          }
        }
      } catch (e) {
        console.log(e)
      }
    },
    goToInvoice(id) {
      try {
        if (this.checkPrivilege('invoice:read')) {
          this.setLocalStorageDisplayMode()
          let route = this.$router.resolve({
            path: '/invoice/' + id + '?modedetail=true',
          })
          window.open(route.href, '_blank')
        }
      } catch (e) {
        console.log(e)
      }
    },
    getUploadHistoryList() {
      try {
        const url = this.$store.getters.apiInvoiceUploadHistoryUrl
        const data = {
          headers: {
            Authorization: this.$store.getters.getAuthToken,
          },
          params: {
            date_from: this.searchForm.dateFrom,
            date_to: this.searchForm.dateTo,
          },
        }
        return new Promise((resolve) => {
          Axios.get(url, data).then((response) => {
            if (response.data.status !== 'success') {
              return resolve(false)
            }
            this.uploadHistory = this.shapeDataUploadHistoryList(
              response.data.upload_lists
            )
            resolve(response)
          })
        })
      } catch (error) {
        console.log(error)
      }
    },
    removeDuplicateData(list) {
      try {
        let result = JSON.parse(JSON.stringify(list))
        for (let i = result.length - 1; i >= 0; i--) {
          if (
            this.uploadHistory.some(
              (item) => item.local_id === result[i].local_id
            )
          ) {
            result.splice(i, 1)
          }
        }
        return result
      } catch (error) {
        console.log(error)
      }
    },
    shapeDataUploadHistoryList(list) {
      try {
        list.forEach((item) => {
          if ('result' in item) {
            item.state = item.result
          }
          if ('date_created' in item) {
            item.date_created = item.date_created.replace('T', ' ')
          }
        })
        return list
      } catch (e) {
        console.log(e)
      }
    },
    getUserData() {
      try {
        const auth_token = this.$store.getters.getAuthToken
        const organization_id = this.$store.getters.getOrganizationID

        Axios.get(this.$store.getters.apiUserListFunction, {
          headers: { Authorization: auth_token },
          params: { organization_id: organization_id, user_role_id: 1 },
        }).then((response) => {
          if (response.data.error) {
            Snackbar.error(response.data.error)
          } else {
            this.userList = response.data.user_list
            this.sysUser = response.data.sys_user
          }
        })
      } catch (e) {
        console.log(e)
      }
    },
    getTableData() {
      try {
        this.getUploadHistoryList().then((response) => {
          if (response) {
            this.$store.dispatch('getUploadList', {})
            this.loadingPage = false
          }
        })
      } catch (e) {
        console.log(e)
      }
    },
    getDuplicateIds(item) {
      try {
        let duplicate_ids = item.error.params.duplicate_local_ids
        return duplicate_ids.join(', ')
      } catch (e) {
        console.log(e)
        return ''
      }
    },
    getFileName(item) {
      try {
        return item.upload_source
      } catch (e) {
        console.log(e)
        return ''
      }
    },
    getErrorName(item) {
      try {
        return item.error.name
      } catch (e) {
        console.log(e)
        return ''
      }
    },
    getUserName(id) {
      try {
        const obj = this.userList.find((x) => x.id === id)
        if (obj && obj.user) {
          return obj.user.full_name
        } else if (this.sysUser && this.sysUser.id == id) {
          return this.sysUser.user.full_name
        } else {
          return ''
        }
      } catch (e) {
        console.log(e)
        return ''
      }
    },
    getErrorMessage(item) {
      try {
        let text = this.getDuplicateMessage(item)
        if (text != '') {
          return text
        } else {
          const name = item.error.name
            ? convertUplaodErrorMessage(item.error.name)
            : ''
          return name
        }
      } catch (e) {
        console.log(e)
        return ''
      }
    },
    getDuplicateMessage(item) {
      try {
        if (
          item.error.name == 'DuplicateFileName' &&
          item.error.params.duplicate_local_ids
        ) {
          let ids = item.error.params.duplicate_local_ids
          let text = '同じファイルがアップロードされました。 #'
          ids.forEach((x) => {
            text = text + x + ','
          })
          return text.slice(0, -1)
        } else {
          return ''
        }
      } catch (e) {
        console.log(e)
        return ''
      }
    },
    convertToDate(value) {
      try {
        const arr = value.split('-')
        if (arr.length == 3) {
          return new Date(arr[0], arr[1] - 1, arr[2].slice(0, 2))
        }
      } catch (e) {
        console.log(e)
      }
    },
    setInitialDate() {
      try {
        let nowDate = new Date(),
          fromDate = new Date()
        fromDate.setDate(fromDate.getDate() - 6)
        let now_year = nowDate.getFullYear()
        let now_month = ('0' + (nowDate.getMonth() + 1)).slice(-2)
        let now_day = ('0' + nowDate.getDate()).slice(-2)
        let from_year = fromDate.getFullYear()
        let from_month = ('0' + (fromDate.getMonth() + 1)).slice(-2)
        let from_day = ('0' + fromDate.getDate()).slice(-2)
        this.searchForm.dateTo = now_year + '-' + now_month + '-' + now_day
        this.searchForm.dateFrom = from_year + '-' + from_month + '-' + from_day
      } catch (e) {
        console.log(e)
      }
    },
  },
}
</script>
