<template>
  <v-container v-if="checkPrivilege('invoice:upload')" fluid pa-0>
    <v-row no-gutters>
      <v-col cols="5">
        <v-card flat class="pa-8 h-without-header">
          <invoice-dropzone
            ref="invoiceDropzone"
            :organization="organization"
            :definition="definition"
            :journal-items="journalItems"
          />
        </v-card>
      </v-col>
      <v-col cols="7">
        <v-card flat class="pa-5 h-without-header">
          <invoice-upload-table ref="uploadTable" :user="user" />
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapGetters } from 'vuex'
import InvoiceUploadTable from './components/InvoiceUploadTable'
import InvoiceDropzone from './components/InvoiceDropzone'

export default {
  components: {
    InvoiceUploadTable,
    InvoiceDropzone,
  },
  props: {
    user: Object,
    organization: Object,
    definition: Object,
  },
  data() {
    return {
      updateNotificationRequestParam: {},
      uploadQueueIds: [],
    }
  },
  beforeMount() {},
  destroyed() {
    try {
      document.querySelector('html').style.overflow = 'hidden'
      const defaultLayout = document.querySelector(
        '.app-default-layout .v-content__wrap'
      )
      if (defaultLayout) {
        defaultLayout.style.overflow = 'auto'
      }
    } catch (e) {
      console.log(e)
    }
  },
  computed: {
    ...mapGetters({
      journalItems: 'masterList',
    }),
    invoiceSidebar: {
      get() {
        return this.$store.getters.invoiceSidebar
      },
      set(val) {
        this.$store.dispatch('toggleInvoiceSidebar', val)
      },
    },
  },
}
</script>
