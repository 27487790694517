var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"mx-2"},[_c('v-row',{staticClass:"my-4",attrs:{"align-content":"center"}},[_c('span',{staticClass:"fs-10 mr-2 pt-2"},[_vm._v("表示期間")]),_c('date-pick-input',{attrs:{"max":_vm.searchForm.dateTo},on:{"change":_vm.getTableData},model:{value:(_vm.searchForm.dateFrom),callback:function ($$v) {_vm.$set(_vm.searchForm, "dateFrom", $$v)},expression:"searchForm.dateFrom"}}),_c('span',{staticClass:"mx-2 pt-1"},[_vm._v(" 〜 ")])],1),_c('app-section-loader',{attrs:{"status":_vm.loadingPage}}),_c('v-row',{staticClass:"mt-4"},[_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.computedTableData,"fixed-header":"","items-per-page":20,"height":'calc(100vh - 200px)',"footer-props":{
        'items-per-page-text': '表示件数',
        'items-per-page-options': [20],
      },"options":_vm.options},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"item.upload_source",fn:function(ref){
      var item = ref.item;
return [_vm._v("\n        "+_vm._s(item.upload_source)+"\n        "),_c('span',{directives:[{name:"show",rawName:"v-show",value:(!item.multiple && item.upload_source_page),expression:"!item.multiple && item.upload_source_page"}],staticClass:"sw-badge-info",staticStyle:{"white-space":"inherit","text-align":"left"}},[_vm._v("\n          page: "+_vm._s(item.upload_source_page)+"\n        ")]),(_vm.getDuplicateMessage(item) != '')?_c('s-icon',{staticClass:"tw-float-right",attrs:{"color":"red-500","icon":"feather-alert-triangle","tooltip":_vm.getDuplicateMessage(item)}}):_vm._e()]}},{key:"item.date_created",fn:function(ref){
      var item = ref.item;
return [_c('span',{staticClass:"mr-1"},[_vm._v("\n          "+_vm._s(item.date_created.slice(5, 10).replace('T', ' ').replace('-', '/'))+"\n        ")]),_c('span',[_vm._v("\n          "+_vm._s(item.date_created.slice(11, 16))+"\n        ")])]}},{key:"item.user",fn:function(ref){
      var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm.getUserName(item.user_organization_id)))])]}},{key:"item.file_size",fn:function(ref){
      var item = ref.item;
return [_c('div',{staticClass:"text-right"},[_vm._v("\n          "+_vm._s(_vm.getFileSize(item))+"\n        ")])]}},{key:"item.upload_way",fn:function(ref){
      var item = ref.item;
return [_c('div',{staticClass:"text-center"},[(_vm.getUploadWay(item.upload_way).content != '')?_c('v-chip',{staticClass:"px-2",attrs:{"x-small":"","label":"","outlined":"","color":_vm.getUploadWay(item.upload_way).color}},[_vm._v("\n            "+_vm._s(_vm.getUploadWay(item.upload_way).content)+"\n          ")]):_vm._e()],1)]}},{key:"item.state",fn:function(ref){
      var item = ref.item;
return [_c('div',{staticClass:"text-center"},[(item.state === 'WAITING')?_c('v-chip',{staticClass:"px-6",attrs:{"small":"","label":"","dark":""}},[_vm._v("\n            読み取り待機中\n          ")]):(item.state === 'STARTED')?_c('v-chip',{staticClass:"px-6",attrs:{"small":"","label":"","dark":""}},[_vm._v("\n            "+_vm._s(_vm.$t('message.upload_tbl_uploading'))+"\n          ")]):(item.state === 'SUCCESS')?_c('v-btn',{attrs:{"small":"","color":"blue","dark":""},on:{"click":function($event){return _vm.goToInvoice(item.local_id)}}},[_vm._v("\n            "+_vm._s(_vm.$t('message.upload_tbl_success'))+"\n            "),_c('v-icon',{attrs:{"dark":"","right":""}},[_vm._v(" check_circle ")])],1):(item.state === 'FAILURE')?_c('v-btn',{attrs:{"color":"yellow","small":""}},[_vm._v("\n            "+_vm._s(_vm.$t('message.invoice_upload_on_error_process'))+"\n            "),_c('br'),_vm._v("\n            "+_vm._s(_vm.$t('message.invoice_upload_contact_support'))+"\n          ")]):_vm._e()],1)]}},{key:"item.error",fn:function(ref){
      var item = ref.item;
return [_vm._v("\n        "+_vm._s(_vm.getErrorMessage(item))+"\n      ")]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }